<template>
  <div>

    <div style="margin: 10px 0">
      <!-- <el-upload action="http://localhost:9090/animal/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  >

<!--      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>-->
      <el-table-column prop="animalName" label="宠物名称"></el-table-column>
      <el-table-column prop="variety" label="品种"></el-table-column>

      <el-table-column prop="startTime" label="开始时间"></el-table-column>
      <el-table-column prop="endTime" label="结束时间"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>

      <el-table-column label="方式">
        <template slot-scope="scope">
          {{ scope.row.washMethod }}

          <!-- 显示地址和电话，仅当 deliveryMethod 为 '上门' 时 -->
          <div v-if="scope.row.washMethod === '商家上门'">
            <b >地址：</b>{{ scope.row.address }}
            <b >电话：</b>{{ scope.row.phone }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="金额"></el-table-column>
      <el-table-column prop="paymentMethod" label="付款方式"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.opStatus==='已下单'" type="info">{{scope.row.opStatus}}</el-tag>
          <el-tag v-if="scope.row.opStatus==='处理中'" type="primary">{{scope.row.opStatus}}</el-tag>
          <el-tag v-if="scope.row.opStatus==='已完成'" type="success">{{scope.row.opStatus}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="changeOpStatus(scope.row, '处理中')">处理中 <i class="el-icon-check"></i></el-button>
          <el-button type="primary" @click="changeOpStatus(scope.row, '已完成')" >已完成 <i class="el-icon-check"></i></el-button>
        </template>
      </el-table-column>

    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Animal",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      adopt: ''
    }
  },
  created() {
    this.load()
  },
  methods: {
    changeState(row, state) {
      this.request.post("/washCareApply/state/" + row.id + "/" + state).then(res => {
        this.$message.success("操作成功")
        this.load()
      })
    },
    changeOpStatus(row,state){
      this.request.post("/washCareApply/opstatus/" + row.id + "/" + state).then(res => {
        this.$message.success("操作成功")
        this.load()
      })
    },
    load() {
      this.request.get("/washCareApply/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          adopt: this.adopt,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
